import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';
import Title from '../../Title/Title';
import Text from '../../Text/Text';
import Glyph from '../../Glyph/Glyph';
import logo from '../../assets/valleypression_logo_inverse_sans_texte_du_bas_transparent.svg'

const Header = props => {
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

    const hamburgerMenuClickHandler = event => {        
        setMobileMenuIsOpen(!mobileMenuIsOpen);        
    }

    useEffect(() => {
        document.body.style.overflow = mobileMenuIsOpen ? 'hidden' : '';
    }, [mobileMenuIsOpen]);

    return (
        <>
            <header className={styles.container}>
                <div className={styles['top-part-header']}>                    
                    <a href="/">                        
                        <img className={styles.logo} src={logo} alt='logo Valley Pression' height="120" width="158" />
                    </a>
                    <div className={styles.headerTitle}>
                        <div className={styles.mainTitleWrapper}>
                            <Title level='1'>Lavage haute pression</Title>
                        </div>                    
                        <Title level='2'>Résidentiel, commercial et industriel</Title>
                    </div>
                    <div className={styles.headerContact}>                    
                        <Title level='3' underline={true}>Soumission gratuite</Title>                    
                        <a href='tel:4502887289'>
                            <span className={styles.cellIcon}>📱</span>
                            <div className={styles.textWrapper}>
                                <Text size='lg'>450 288-7289</Text>
                            </div>
                        </a>
                        <a href='mailto:info@valleypression.ca'>
                            <span className={styles.emailIcon}>✉</span>
                            <div className={styles.textWrapper}>
                                <Text size='lg'>info@valleypression.ca</Text>
                            </div>                        
                        </a>
                    </div>
                </div>                
                <nav className={styles['nav-bar']}>
                    <div className={styles['hamburgerMenu-icon-container']}>
                        <Glyph glyphIcon={mobileMenuIsOpen ? 'close' : 'hamburgerMenu'} fontPrimaryColor={true} onClick={hamburgerMenuClickHandler} />
                    </div>
                    <ul className={mobileMenuIsOpen ? styles['menu-opened'] : ''}>
                        <li><a href='/'>Accueil</a></li>
                        <li><a href='/services'>Services</a></li>
                        <li><a href='/realisations'>Réalisations</a></li>
                        <li><a href='/contact'>Nous joindre</a></li>
                    </ul>                    
                </nav>
            </header>                        
        </>
    );
};

Header.propTypes = {
    
};

export default Header;