import React from 'react';
import PropTypes from 'prop-types';
import Glyph from '../Glyph/Glyph';
import Text from '../Text/Text';
import styles from './GlyphAndText.module.scss';

GlyphAndText.propTypes = {
    glyph: PropTypes.string,
    text: PropTypes.string
};

function GlyphAndText(props) {  
    return (
        <div className={styles.glyphAndTextContainer}>
            <div className={styles.glyphWrapper}>
                <Glyph glyphIcon={props.glyph}/>
            </div>            
            <div className={styles.textWrapper}>
                <Text size='md'>{props.text}</Text>
            </div>            
        </div>
    );
}

export default GlyphAndText;