import React from 'react';
import PropTypes from 'prop-types';
import styles from './Text.module.scss'

const Text = props => {
    let className;        
    switch (props.size) {
        case 'xs':
            className = styles.textXs;
            break;
        case 'sm':
            className = styles.textSm;
            break;
        case 'lg':
            className = styles.textLg;
            break;
        case 'xl':
            className = styles.textXl;
            break;
        default:
            className = styles.textMd;
            break;
    }
    
    className = props.underline === true ? className + ' ' + styles.underline : className;    
    
    return (
        <span className={className}>{props.children}</span>
    );
};

Text.propTypes = {
    children: PropTypes.string,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
    underline: PropTypes.bool
};

Text.defaultProps = {
    children: '',
    size: 'md'
}

export default Text;