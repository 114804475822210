import styles from './Footer.module.scss';

const Footer = () => {
    const year = new Date().getFullYear();

    return (
        <footer className={styles.footer}>
            <div>Tous droits réservés. © Valley Pression {year}</div>
        </footer>
    );        
}

export default Footer;