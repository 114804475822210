import React from 'react';
import PropTypes from 'prop-types';

Title.propTypes = {
    level: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
    underline: PropTypes.bool
};

function Title(props) {
    let className = props.underline === true ? 'underline' : null;
    switch (props.level) {        
        case '2':
            return (<h2 className={className}>{props.children}</h2>);
        case '3':
            return (<h3 className={className}>{props.children}</h3>);
        case '4':
            return (<h4 className={className}>{props.children}</h4>);
        case '5':
            return (<h5 className={className}>{props.children}</h5>);
        case '6':
            return (<h6 className={className}>{props.children}</h6>);
        default:            
            return (<h1 className={className}>{props.children}</h1>);
    }    
}

export default Title;