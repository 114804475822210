import React from 'react';
import PropTypes from 'prop-types';
import GlyphAndText from '../GlyphAndText/GlyphAndText';
import styles from './ContactPerson.module.scss';

ContactPerson.propTypes = {
    contactName: PropTypes.string,
    contactPhone: PropTypes.string,
    contactEmail: PropTypes.string    
};

function ContactPerson(props) {
    const person = {glyph: 'person', text: props.contactName}
    const cell = {glyph: 'cell', text: props.contactPhone}
    const email = {glyph: 'email', text: props.contactEmail}
    return (
        <div className={styles.contactPersonContainer}>
            <GlyphAndText {...person} />
            <GlyphAndText {...cell} />
            <GlyphAndText {...email} />
        </div>
    );
}

export default ContactPerson;