import RealisationsSection from "../blocks/Sections/Realisations/Realisations";
import { Helmet } from "react-helmet";

const Realisations = () => {
    return (
        <>
            <Helmet>
                <title>Valley Pression - Quelques réalisations</title>
                <meta name="description" content="Voici quelques exemples de travaux que nous avons effectués jusqu'à maintenant." />
            </Helmet>
            <RealisationsSection />
        </>
    );
}

export default Realisations;