import React from 'react';
import PropTypes from 'prop-types';
import styles from './Paragraph.module.scss';

Paragraph.propTypes = {
    children: String,
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl'])
};

function Paragraph(props) {
    let className;
    switch (props.size) {
        case 'xs':
            className = styles.paragraphXs;
            break;
        case 'sm':
            className = styles.paragraphSm;
            break;
        case 'lg':
            className = styles.paragraphLg;
            break;
        case 'xl':
            className = styles.paragraphXl;
            break;
        default:
            className = styles.paragraphMd;
            break;
    }

    return (
        <p className={className}>{props.children}</p>
    );
}

Paragraph.defaultProps = {
    children: '',
    size: 'md'
}

export default Paragraph;