import React from 'react';
import PropTypes from 'prop-types';
import ContactPersons from '../../ContactPersons/ContactPersons';
import SectionContainer from '../../SectionContainer/SectionContainer';
import Paragraph from '../../Paragraph/Paragraph';
import styles from './ContactPersonsBlock.module.scss';

ContactPersonsBlock.propTypes = {    
    persons: PropTypes.array
};

function ContactPersonsBlock(props) {
    facebookSdkInit();
    const {persons} = props;
    return (
        <div className={styles.contactPersonsBlockContainer}>
            <SectionContainer classNameContent={styles.content} sectionTitle='Nous joindre'>
                <div className={styles.firstParagraphWrapper}>
                    <Paragraph size='md'>Pour une soumission ou toute autre demande d'informations, n'hésitez pas à nous contacter.</Paragraph>
                </div>                
                <div className={styles.contactComponentWrapper}>
                    <ContactPersons persons={persons} />
                </div>
                <div className={styles.facebookParagraphWrapper}>
                    <Paragraph size='md'>Vous pouvez également suivre nos activités sur Facebook.</Paragraph>
                    <span class="fb-like" data-href="https://www.facebook.com/valleypression" data-width="200" data-layout="button_count" data-action="like" data-show-faces="false" data-share="false"></span>
                </div>                
            </SectionContainer>                        
        </div>
    );
}

const facebookSdkInit = () => {
    return new Promise(() => {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/fr_CA/sdk.js#xfbml=1&version=v2.3";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    })
}

export default ContactPersonsBlock;