import './App.css';
import Home from './components/pages/Home';
import Services from './components/pages/Services';
import Realisations from './components/pages/Realisations';
import Contact from './components/pages/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/blocks/Header/Header';
import Footer from './components/blocks/Footer/Footer';


function App() {
    return (
        <Router>
            <div className='main-container'>
                <div className='content-wrapper'>
                    <Header />
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/services" element={<Services />} />
                        <Route path="/realisations" element={<Realisations />} />
                        <Route path="/contact" element={<Contact />} />
                    </Routes>
                    <Footer />
                </div>
            </div>
        </Router>
    );
}

export default App;
