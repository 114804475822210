import styles from './Intro.module.scss';

import SectionContainer from '../../../SectionContainer/SectionContainer';

import truckImg from '../../../assets/camion_trailer.png';

const Intro = props => {
    return(
        <SectionContainer classNameContent={styles.content} sectionTitle='Valley Pression'>
            <img id="Container-present-img1" src={truckImg} />

            <p>Valley Pression est une nouvelle entreprise qui oeuvre dans le domaine du lavage haute pression depuis 2014. Grâce à notre unité mobile et à la diversité de nos équipements, nous sommes en mesure d'offrir différents types de travaux de lavage résidentiel, commercial et industriel.</p>
            <br />
            <p>Notre mission : faire un travail de qualité et à la satisfaction de notre clientèle.</p>
            <br />
            <p>Une nouveauté cette année, nous arrosons désormais contre les araignées sur l'extérieur des bâtiments résidentiels, commerciaux et industriels.</p>
            <br />
            <p>Consultez la section Service pour plus de renseignements sur les services que nous offrons.</p>
        </SectionContainer>
    );    
}

export default Intro;