import ContactPersonsBlock from "../blocks/ContactPersonsBlock/ContactPersonsBlock";
import { Helmet } from "react-helmet";

const Contact = () => {
    const persons = [

        {
            contactName: 'Sylvain Haineault',
            contactPhone: '450 288-7289',
            contactEmail: 'sylvain.haineault@valleypression.ca'
        },
        {
            contactName: 'Nicolas Haineault',
            contactPhone: '450 288-1080',
            contactEmail: 'nicolas.haineault@valleypression.ca'
        }
    ]
    return (
        <>
            <Helmet>
                <title>Valley Pression - Nous joindre</title>
                <meta name="description" content="Pour toutes informations, vous pouvez nous joindre par téléphone au 450 288-7289 ou par courriel à info @valleypression.ca" />
            </Helmet>
            <ContactPersonsBlock persons={persons} />
        </>
    );
}

export default Contact;