import ServicesSection from "../blocks/Sections/Services/Services";
import { Helmet } from "react-helmet";

const Services = () => {
    return (
        <>
            <Helmet>
                <title>Valley Pression - Liste des services offerts</title>
                <meta name="description" content="Lavage de flottes de camions, extérieur de maison et bâtisse commerciale, enlèvement de graffitis, dégommage, arrosage contre les araignées et bien plus." />
            </Helmet>
            <ServicesSection />
        </>
    );
}

export default Services;