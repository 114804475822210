import styles from './Carousel.module.scss';
import { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

// image
import imgLeaf from '../assets/Carousel/FeuilleCarousel.png';

const Carousel = props => {
    //param<etres du caroussel
    const parameters = {
        autoSlide: true,
        autoSlideIntervalInMs: 5000,
        slideTransitionSpeedInMs: 400,
        navigationControlsShowHideSpeedInMs: 400,
        imageRatio: 290 / 960,
        smallSize: {
            getCarouselWidth: () => { },
        },
        mediumSize: {
            getCarouselWidth: () => { },
        },
        largeSize: {
            getCarouselWidth: () => { },
        },
        ...props.parameters
    }

    const [carouselWidth, setCarouselWidth] = useState(() => {
        if (window.innerWidth < 768) {
            return parameters.smallSize.getCarouselWidth();
        }
        else if (window.innerWidth < 992) {
            return parameters.mediumSize.getCarouselWidth();
        }
        else {
            return parameters.largeSize.getCarouselWidth();
        }
    });

    const [carouselHeight, setCarouselHeight] = useState(() => {
        if (window.innerWidth < 768) {
            return parameters.smallSize.getCarouselWidth() * parameters.imageRatio;
        }
        else if (window.innerWidth < 992) {
            return parameters.mediumSize.getCarouselWidth() * parameters.imageRatio;
        }
        else {
            return parameters.largeSize.getCarouselWidth() * parameters.imageRatio;
        }
    });

    const [isCarouselOnMouseOver, setIsCarouselOnMouseOver] = useState(false);
    const [currentImgSelected, setCurrentImgSelected] = useState(0);
    const [prevImgSelected, setPrevImgSelected] = useState(0);
    const [timer, setTimer] = useState();

    const navigationControlsSpeedTransitionStyle = `${parameters.navigationControlsShowHideSpeedInMs / 1000}s`;
    const nbImageInCarousel = props.imagesList.length;

    const sliderProps = useSpring({
        from: { marginLeft: `${prevImgSelected * -(carouselWidth)}px` },
        to: { marginLeft: `${currentImgSelected * -(carouselWidth)}px` },
        config: { duration: parameters.slideTransitionSpeedInMs }
    });

    const CarouselMouseEnterHandler = () => {
        setIsCarouselOnMouseOver(true);
        if (parameters.autoSlide) {
            clearInterval(timer);
            setTimer(0);
        }
    }

    const CarouselMouseLeaveHandler = () => {
        setIsCarouselOnMouseOver(false);
        if (parameters.autoSlide) {
            const intervalId = setInterval(NextButtonClickHandler, parameters.autoSlideIntervalInMs);
            console.log('mouseLeave' + parameters.autoSlideIntervalInMs)
            setTimer(intervalId);
        }
    }

    //Fonction pour afficher l'image suivante
    const NextButtonClickHandler = ev => {
        if (ev !== undefined)
            ev.preventDefault();

        setCurrentImgSelected(prev => {
            setPrevImgSelected(prev);
            prev++;
            if (prev > nbImageInCarousel - 1) {
                prev = 0;
            }

            return prev;
        });
    }

    //Fonction pour afficher l'image précédente
    const PrevButtonClickHandler = ev => {
        if (ev !== undefined)
            ev.preventDefault();

        setCurrentImgSelected(prev => {
            setPrevImgSelected(prev);
            prev--;
            if (prev < 0) {
                prev = nbImageInCarousel - 1;
            }

            return prev;
        });
    }

    const BulletClickHandler = (ev, index) => {
        if (ev !== undefined)
            ev.preventDefault();

        setPrevImgSelected(currentImgSelected);
        setCurrentImgSelected(index);
    }

    // const CarouselTouchStartHandler = () => {
    //     //On pourrait gérer cet évenement pour pouvoir changer l'image du carousel avec le doigt en mobile
    //     console.log('TouchStart');
    // }

    // const CarouselTouchMoveHandler = (ev) => {
    //     //On pourrait gérer cet évenement pour pouvoir changer l'image du carousel avec le doigt en mobile
    //     console.log('TouchMove');
    // }

    useEffect(() => {
        const intervalId = setInterval(NextButtonClickHandler, parameters.autoSlideIntervalInMs);
        setTimer(intervalId);
        if (!parameters.autoSlide) {
            clearInterval(timer);
            setTimer(0);
        }

        return () => clearInterval(timer);
    }, [])

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setCarouselWidth(parameters.smallSize.getCarouselWidth());
                setCarouselHeight(parameters.smallSize.getCarouselWidth() * parameters.imageRatio);
            }
            else if (window.innerWidth < 992) {
                setCarouselWidth(parameters.mediumSize.getCarouselWidth());
                setCarouselHeight(parameters.mediumSize.getCarouselWidth() * parameters.imageRatio);
            }
            else {
                setCarouselWidth(parameters.largeSize.getCarouselWidth);
                setCarouselHeight(parameters.largeSize.getCarouselWidth() * parameters.imageRatio);
            }
        }

        window.addEventListener('resize', handleResize);
        return _ => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        // container du carousel
        <div className={styles['carousel']} style={{ width: carouselWidth, height: carouselHeight }}
            onMouseEnter={CarouselMouseEnterHandler} onMouseLeave={CarouselMouseLeaveHandler}>
            <button className={`${styles['left-arrow-button']} ${isCarouselOnMouseOver ? styles['show-control'] : ''}`}
                style={{ transition: `${navigationControlsSpeedTransitionStyle}` }} onClick={PrevButtonClickHandler} />
            <div className={styles['carousel-container']}
                // onTouchStart={CarouselTouchStartHandler} onTouchMove={CarouselTouchMoveHandler}
                style={{ width: carouselWidth, height: carouselHeight }}>
                <animated.ul className={styles.slider} style={sliderProps} >
                    {props.imagesList.map((image, index) => (
                        <li key={index}>
                            <img id={`Container-present-img${index}`} src={image} alt={`slider ${index + 1}`} style={{ width: `${carouselWidth}px`, height: `${carouselHeight}px` }} />
                        </li>
                    ))}
                </animated.ul>
            </div>
            <button onClick={NextButtonClickHandler} className={`${styles['right-arrow-button']} ${isCarouselOnMouseOver ? styles['show-control'] : ''}`}
                style={{ transition: `${navigationControlsSpeedTransitionStyle}` }} />
            {/* Controles de navigation du carousel */}
            <ul className={`${styles['carousel-navigator']} ${isCarouselOnMouseOver ? styles['show-control'] : ''}`} style={{ transition: `${navigationControlsSpeedTransitionStyle}` }}>
                {props.imagesList.map((image, index) => (
                    <li key={index} className={`${currentImgSelected === index ? styles['slide-selected'] : ''}`} onClick={(ev) => BulletClickHandler(ev, index)}>
                        <img id={`left-navigation-img${index}`} src={imgLeaf} alt={`bullet ${index + 1}`} />
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default Carousel;