import styles from './Services.module.scss';
import SectionContainer from '../../../SectionContainer/SectionContainer';


const ServicesSection = props => {
    return (
        <SectionContainer classNameContent={styles.content} sectionTitle='Services'>
            <p>Notre unité mobile bien équipée nous permet de vous offrir un service de lavage à haute pression résidentiel, commercial et industriel. Voici la liste des services que nous pouvons vous offrir :</p>
            <br />
            <ul className={styles['list-level1']}>            
                <li>Lavage de</li>
                <li style={{listStyle: 'none'}}>
                    <ul className={styles['list-level2']}>                    
                        <li>Module de jeux</li>
                        <li>Balcon et terrasse</li>
                        <li>Extérieur de maison et bâtisse commerciale</li>
                        <li>Toiture de tôle</li>
                        <li>Allée de béton et pavé uni</li>
                        <li>Plancher grande surface (garage et usine)</li>
                        <li>Roulotte et bateau</li>
                        <li>Flotte de camions</li>
                        <li>Machinerie lourde</li>
                    </ul>
                </li>
                <li>Arrosage contre les araignées</li>
                <li>Enlèvement de graffitis</li>
                <li>Dégommage</li>
                <li>Et plus…</li>
            </ul>
            <br />
            <p>
                De plus, si la température le permet (en haut de -5 °C), il est possible de faire certains travaux de déglaçage en hiver.
                Pour un lavage en eau chaude ou froide, nous sommes là prêts à vous servir!
            </p>
        </SectionContainer>
    );
}

export default ServicesSection;