import styles from './Realisations.module.scss';
import SectionContainer from '../../../SectionContainer/SectionContainer';
import Carousel from '../../../Carousel/Carousel';

// images
import imgToiture1 from '../../../assets/Toiture_avant.png';
import imgToiture2 from '../../../assets/Toiture_apres.png';

import imgCamion1 from '../../../assets/Camion1.png';
import imgCamion2 from '../../../assets/Camion2.png';
import imgKenworth1 from '../../../assets/Kenworth1.png';

import imgGarage1 from '../../../assets/garage1.png';
import imgGarage2 from '../../../assets/garage2.png';

const RealisationsSection = props => {
    const carouselImageListToiture = [imgToiture1, imgToiture2];
    const carouselImageListCamion = [imgCamion1, imgCamion2, imgKenworth1];
    const carouselImageListGarage = [imgGarage1, imgGarage2];

    const carouselParams = {
        imageRatio: 200 / 400,
        smallSize: {
            getCarouselWidth: () => { return window.innerWidth - 90; }, //Full width - 90px
        },
        mediumSize: {
            getCarouselWidth: () => { return window.innerWidth * 0.7; }, //70% du with de la fenêtre
        },
        largeSize: {
            getCarouselWidth: () => { return 400; }, //fixe 400px
        }
    }

    return (
        <SectionContainer classNameContent={styles.content} sectionTitle='Réalisations'>
            <p style={{ paddingBottom: '20px' }}>
                Voici quelques travaux que nous avons effectués jusqu'à maintenant.
            </p>

            <div className={styles['realisations-container']}>
                <div className={styles['realisations-content']}>
                    <div className={styles.item}>
                        <p>Même si votre toiture de tôle a plus de 30 ans, grâce à notre savoir-faire ainsi qu'à notre puissant équipement, nous sommes en mesure de lui redonner l'air d'une neuve.
                        </p>
                    </div>
                    <div className={styles.item}>
                        <Carousel imagesList={carouselImageListToiture} parameters={carouselParams} />
                    </div>
                </div>

                <div className={styles['realisations-content']}>
                    <div className={`${styles['mobile-order2']} ${styles.item}`}>
                        <Carousel imagesList={carouselImageListCamion} parameters={carouselParams} />
                    </div>
                    <p className={`${styles['mobile-order1']} ${styles.item}`}>Avec notre unité mobile, nous nous déplaçons chez les clients pour laver leur flotte de camions.</p>
                </div>

                <div className={styles['realisations-content']}>
                    <p className={styles.item}>Voici le résultat après le nettoyage d'un plancher de garage de mécanique.</p>
                    <div className={styles.item}>
                        <Carousel imagesList={carouselImageListGarage} parameters={carouselParams} />
                    </div>
                </div>
            </div>            
        </SectionContainer>
    );
}

export default RealisationsSection;