import Carousel from "../Carousel/Carousel";
import Intro from "../blocks/Sections/Intro/Intro";

// image
import imgArrosage from '../assets/arrosage_arr1.png';
import imgPrestigeFord from '../assets/Prestigeford1.png';
import imgExcavation from '../assets/excavation1.png';
import { Helmet } from "react-helmet";

const Home = () => {    
    const carouselImageList = [imgArrosage, imgExcavation, imgPrestigeFord];
    const carouselParams = {
        imageRatio: 290 / 960,
        smallSize: {
            getCarouselWidth: () => { return window.innerWidth - 50; }, //Full width - 50px
        },
        mediumSize: {
            getCarouselWidth: () => { return window.innerWidth * 0.8; }, //80% du with de la fenêtre
        },
        largeSize: {
            getCarouselWidth: () => { return 960; }, //fixe 960px
        }
    }

    return (
        <>
            <Helmet>
                <title>Valley Pression - Lavage haute pression résidentiel, commercial et industriel</title>
                <meta name="description" content="Valley Pression offre un service professionnel de lavage haute pression en eau chaude ou froide." />
            </Helmet>
            <Carousel imagesList={carouselImageList} parameters={carouselParams} />
            <Intro />
        </>
    );
}

export default Home;