import React from 'react';
import PropTypes from 'prop-types';
import ContactPerson from '../ContactPerson/ContactPerson';
import styles from './ContactPersons.module.scss';

ContactPersons.propTypes = {
    person: PropTypes.array
};

function ContactPersons(props) {
    const contactPersons = [];
    for(let person of props.persons){
        const contactPerson = (
            <ContactPerson {...person} />
        )
        contactPersons.push(<div className={styles.contactPersonContainer}>{contactPerson}</div>)
    }
    return <div className={styles.contactPersonsContainer2Column}>{contactPersons}</div>;
}

export default ContactPersons;