import React from 'react';
import PropTypes from 'prop-types';
import styles from './Glyph.module.scss';

Glyph.propTypes = {
    glyphIcon: PropTypes.oneOf(['person', 'cell', 'email', 'hamburgerMenu']),
    fontPrimaryColor: PropTypes.bool
};

function Glyph(props) {
    let className = styles.glyphIcon + ' ' + getGlyphStyle(props.glyphIcon);
    className = props.fontPrimaryColor === true ? className + ' ' + styles.fontPrimaryColor : className;
    return (        
        <span className={className} onClick={props.onClick}>
            {getGlyphCode(props.glyphIcon)}
        </span>
    );
}

function getGlyphCode(glyphName){
    switch (glyphName) {
        case 'person':
            return '👤';            
        case 'cell':
            return '📱';
        case 'email':
            return '✉';
        case 'hamburgerMenu':
            return '☰';
        case 'close':
            return '✖';
    }
}

function getGlyphStyle(glyphName){
    switch (glyphName) {
        case 'person':
            return styles.person;            
        case 'cell':
            return styles.cell;
        case 'email':
            return styles.email;
        case 'hamburgerMenu':
            return styles.hamburgerMenu;
        case 'close':
            return styles.close;
    }
}

export default Glyph;